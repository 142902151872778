import type { FC } from 'react';
import { getTestProps, omitTestProps } from '@mwl/core-lib';

import { GamesGridLayout } from '@/components/GamesGridLayout/GamesGridLayout';
import { LoadableItemsWrapper } from '@/components/LoadableItemsWrapper/LoadableItemsWrapper';

import { CardGrid } from '../CardGrid/CardGrid';
import { GameCard } from '../GameCard/GameCard';

import type { SectionGamesProps } from './SectionGames.types';

import styles from './SectionGames.module.scss';

export const SectionGames: FC<SectionGamesProps> = ({
  games,
  title,
  href,
  onLoad,
  hasMore,
  classes,
  isError,
  isLoading,
  className,
  isInfinite,
  emptyContent,
  totalGamesCount,
  isShowItemsCount,
  scrollThreshold = 0.55,
  infiniteScrollTargetId,
  isShowBadges = true,
  analytics,
  ...props
}) => {
  const loadableProps = isInfinite
    ? ({
        as: 'infinite',
        dataLength: games.length,
        next: onLoad ?? (() => {}),
        hasMore: !!hasMore,
        scrollThreshold,
        scrollContainerId: infiniteScrollTargetId,
      } as const)
    : ({
        as: 'block',
      } as const);

  if (!games.length) {
    if (emptyContent) {
      return (
        <GamesGridLayout className={className} title={title} classes={classes}>
          {emptyContent}
        </GamesGridLayout>
      );
    }

    return null;
  }

  return (
    <GamesGridLayout
      {...getTestProps(props)}
      href={href}
      className={cn(styles.root, className)}
      title={title}
      classes={classes}
      totalGamesCount={totalGamesCount}
      isShowItemsCount={isShowItemsCount}
    >
      <LoadableItemsWrapper {...loadableProps}>
        <CardGrid
          hideHasMore={isInfinite && !isError}
          loadNext={onLoad}
          hasNext={hasMore}
          isInfinite={isInfinite}
          isLoading={!!isLoading}
          {...omitTestProps(props)}
        >
          {games.map((game) => (
            <GameCard {...game} key={game.id} badges={isShowBadges ? game.badges : []} analytics={analytics} />
          ))}
        </CardGrid>
      </LoadableItemsWrapper>
    </GamesGridLayout>
  );
};

export * from './SectionGames.types';
