import type { CSSProperties, FC, SetStateAction } from 'react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import type { Banner } from '@mwl/core-lib';
import { getTestProps, sendAnalyticsData } from '@mwl/core-lib';
import { useIsVisibleWatch } from '@mwl/ui';

import { CarouselItem } from './components/CarouselItem/CarouselItem';
import { Pagination } from './components/Pagination/Pagination';
import { useAutoSlide, useSliderSize } from './Carousel.hooks';
import type { CarouselProps } from './Carousel.types';

import styles from './Carousel.module.scss';

const duplicateCount = 3;
const defaultSlideSize = 787;
const minOffset = 100;

const BaseCarousel: FC<CarouselProps> = ({
  options: initialOptions,
  autoSlide = true,
  autoSlideDisableOnHover = 5000,
  autoSlideTimeout = 5000,
  slideTestMarker,
  analytics,
  ...props
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const to = initialOptions.length;

  const { banner: carouselAnalytics, ...carouselItemAnalytics } = analytics || {};

  const options = useMemo(
    () =>
      Array(duplicateCount)
        .fill(null)
        .flatMap(() => initialOptions),
    [initialOptions],
  );

  const getAnalyticsData = useCallback(
    (item?: Banner) => {
      if (!item) {
        return {};
      }

      return {
        href: item.url,
        img: item.image,
        position_weight: item.weight,
        slide_id: item.id,
        count: initialOptions.length,
      };
    },
    [initialOptions.length],
  );

  const { translateValue, carouselState, carouselRef } = useSliderSize({
    activeIndex,
    minOffset,
    initialOptions,
    defaultSlideSize,
  });

  const isVisible = useIsVisibleWatch(carouselRef.current || undefined);

  const { start, stop, pause, resume } = useAutoSlide({
    timeout: autoSlideTimeout,
    lastIndex: to,
    onSlide: (setNextIndex: SetStateAction<number>) => {
      setActiveIndex(setNextIndex);
    },
    onSlideChanged: (nextIndex: number) => {
      const item = options[nextIndex];

      if (isVisible) {
        sendAnalyticsData(carouselAnalytics?.change?.eventName, {
          ...(carouselAnalytics?.change?.data || {}),
          ...getAnalyticsData(item),
        });
      }
    },
  });

  const handleChangeIndex = useCallback(
    (nextIndex: number) => {
      stop();
      setActiveIndex(nextIndex);
      const item = options[nextIndex];

      sendAnalyticsData(carouselAnalytics?.swipe?.eventName, {
        ...(carouselAnalytics?.swipe?.data || {}),
        ...getAnalyticsData(item),
      });

      start();
    },
    [carouselAnalytics?.swipe?.data, carouselAnalytics?.swipe?.eventName, getAnalyticsData, options, start, stop],
  );

  const handleSlideMouseEnter = useCallback(() => {
    if (!autoSlideDisableOnHover) {
      return;
    }

    pause();
  }, [autoSlideDisableOnHover, pause]);

  const handleSlideMouseLeave = useCallback(() => resume(), [resume]);

  useEffect(() => {
    const handleWindowBlur = () => pause();
    const handleWindowFocus = () => resume();

    window.addEventListener('blur', handleWindowBlur);
    window.addEventListener('focus', handleWindowFocus);

    return () => {
      window.removeEventListener('blur', handleWindowBlur);
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, [pause, resume]);

  useEffect(() => {
    if (autoSlide) {
      start();
    }

    return () => {
      stop();
    };
  }, [autoSlide, start, stop]);

  return (
    <div
      {...getTestProps(props)}
      style={{ '--children-count': initialOptions.length } as CSSProperties}
      className={styles.root}
      ref={carouselRef}
    >
      <div className={styles.wrapper} style={{ transform: `translateX(${translateValue}px)` }}>
        {carouselState?.isReady &&
          options.map((item, currentIndex) => (
            <CarouselItem
              {...slideTestMarker?.(currentIndex.toString()).nodeProps}
              activeIndex={activeIndex}
              currentIndex={currentIndex}
              key={currentIndex}
              onClick={handleChangeIndex}
              onMouseEnter={handleSlideMouseEnter}
              onMouseLeave={handleSlideMouseLeave}
              slideSize={carouselState.slideSize}
              offsetSize={carouselState.offsetSize}
              carouselLength={initialOptions?.length}
              item={item}
              analytics={carouselItemAnalytics}
            />
          ))}
      </div>

      <Pagination
        activeIndex={activeIndex}
        pages={to}
        onChange={handleChangeIndex}
        onMouseEnter={handleSlideMouseEnter}
        onMouseLeave={handleSlideMouseLeave}
      />
    </div>
  );
};

const Carousel = memo(BaseCarousel);

export * from './Carousel.types';
export { Carousel };
