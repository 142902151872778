import { useCallback } from 'react';
import { useToggle } from '@mwl/core-lib';

import type { DropdownClasses, DropdownProps } from '@/components/Dropdown/Dropdown.types';

import styles from '../CasinoFilters.module.scss';

const classes: DropdownClasses = {
  container: styles.dropdownContainer,
  tooltip: styles.dropdownTooltip,
  title: styles.dropdownTitle,
};

export function useDropdown(initialState = false): Omit<DropdownProps, 'children'> {
  const [active, toggleActive] = useToggle(initialState);

  const handleChange = useCallback(
    (nextActive?: boolean) => {
      toggleActive(nextActive);
    },
    [toggleActive],
  );

  return {
    active,
    onToggle: handleChange,
    variant: 'outlined',
    classes,
    maxMobileHeight: 248,
    placement: 'bottom',
    fallbackPlacements: ['bottom-start'],
    width: 'auto',
    className: styles.dropdown,
  };
}
