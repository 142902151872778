import type { ButtonProps } from '../Button/Button.types';

export const SEARCH_RESULT = 'casinoMenu_search_result';
export const SEARCH_NOT_FOUND = 'casinoMenu_searchNotResult';

export const notFoundShowMoreAnalytics: ButtonProps['analytics'] = {
  click: {
    eventName: 'casinoMenu_searchNotResult_showMore_btn_click',
  },
};
