import { useCallback, useEffect, useRef, useState } from 'react';

import type { UseAutoSlideOptions, UseSliderSizeOptions } from '@/components/Carousel/Carousel.types';
import { useMediaWidthDown } from '@/hooks';

export function useAutoSlide({ timeout = 5000, lastIndex = 0, onSlide, onSlideChanged }: UseAutoSlideOptions) {
  const timer = useRef(0);
  const autoSlideSemaphore = useRef(false);

  const start = useCallback(() => {
    timer.current = window.setInterval(() => {
      if (autoSlideSemaphore.current) {
        return;
      }

      onSlide?.((prevIndex) => {
        let nextIndex = prevIndex + 1;

        if (nextIndex >= lastIndex) {
          nextIndex = 0;
        }

        onSlideChanged?.(nextIndex);

        return nextIndex;
      });
    }, timeout);
  }, [lastIndex, onSlide, onSlideChanged, timeout]);

  const stop = useCallback(() => {
    clearInterval(timer.current);
  }, []);

  const pause = useCallback(() => {
    autoSlideSemaphore.current = true;
  }, []);

  const resume = useCallback(() => {
    autoSlideSemaphore.current = false;
  }, []);

  useEffect(() => () => stop(), [stop]);

  return {
    start,
    stop,
    pause,
    resume,
  } as const;
}

export const useSliderSize = ({ activeIndex, minOffset, initialOptions, defaultSlideSize }: UseSliderSizeOptions) => {
  const [carouselState, setCarouselState] = useState({
    offsetSize: minOffset,
    slideSize: defaultSlideSize,
    windowWidth: 0,
    isReady: false,
  });

  const isSmallScreen = useMediaWidthDown('screenSm');
  const carouselRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setCarouselState((prevState) => ({ ...prevState, windowWidth: window?.innerWidth }));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSmallScreen]);

  useEffect(() => {
    if (window && window.innerWidth && carouselRef.current?.offsetWidth) {
      const actualSlideSize = isSmallScreen ? carouselRef.current.offsetWidth : defaultSlideSize;
      const actualOffsetSize = isSmallScreen ? 0 : minOffset;
      const equalSize = (carouselRef.current.offsetWidth - actualSlideSize) / (initialOptions.length - 1);

      setCarouselState({
        offsetSize: isSmallScreen ? (actualSlideSize as number) : Math.max(equalSize, actualOffsetSize),
        slideSize: actualSlideSize as number,
        windowWidth: window?.innerWidth,
        isReady: true,
      });
    }
  }, [
    initialOptions.length,
    carouselRef.current?.offsetWidth,
    minOffset,
    isSmallScreen,
    defaultSlideSize,
    carouselState.windowWidth,
  ]);

  const translateValue = -activeIndex * carouselState.offsetSize;

  return { translateValue, carouselState, carouselRef };
};
