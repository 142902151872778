import type { FC } from 'react';
import { useRouter } from 'next/router';

import {
  casinoNavMenu,
  liveCasinoNavMenu,
  routes,
  sidebarTopSectionNavMenuCasino,
  sidebarTopSectionNavMenuLiveCasino,
} from '@/constants';

import { useMenuItems } from '../../hooks/useMenuItems';
import { SidebarNavMenu } from '../SidebarNavMenu/SidebarNavMenu';
import { SidebarTopSection } from '../SidebarTopSection/SidebarTopSection';

import styles from './Sidebar.module.scss';

export const Sidebar: FC = () => {
  const router = useRouter();

  const menuItems = router.asPath.includes(routes.liveCasino.home) ? liveCasinoNavMenu : casinoNavMenu;

  const menuItemsTop = router.asPath.includes(routes.liveCasino.home)
    ? sidebarTopSectionNavMenuLiveCasino
    : sidebarTopSectionNavMenuCasino;

  const navMenu = useMenuItems(menuItems);
  const navMenuTop = useMenuItems(menuItemsTop);

  return (
    <div className={styles.wrapper}>
      <aside className={styles.root}>
        <SidebarTopSection list={navMenuTop} />
        <SidebarNavMenu list={navMenu} />
      </aside>
    </div>
  );
};
