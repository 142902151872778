import type { GetCasinoProviderListRequestV2, ItemsLoadableLoadParams, SettingsByWindowWidth } from '@mwl/core-lib';
import { getCasinoProviderListV2 } from '@mwl/core-lib';

export const providersSettingsList: Array<SettingsByWindowWidth> = [
  { minWidth: 0, productsPerPage: 1000, addCount: 1000 },
];

export const loadProviders =
  ({ genre = [], feature = [], productType = ['casino'], title = '' }: Partial<GetCasinoProviderListRequestV2>) =>
  async ({ page, size, axiosConfig }: ItemsLoadableLoadParams) => {
    const { data, status, error } = await getCasinoProviderListV2(
      {
        genre,
        feature,
        productType,
        title,
        size,
        page,
      },
      axiosConfig,
    );

    if (status !== 200 || !data) {
      return { isError: true, error };
    }

    return {
      page: page + 1,
      items: data.content ?? [],
      hasMore: !data.last,
      totalElements: data.totalElements || 0,
    };
  };
