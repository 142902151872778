import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import {
  extendAnalyticsData,
  getTestProps,
  reloadCasinoFilters,
  resetCasinoFilters,
  selectCasinoFilters,
  selectCasinoFiltersLoading,
  selectCasinoFiltersSearchValue,
  selectCasinoFiltersSearchVisible,
  sendAnalyticsData,
  setCasinoFiltersResultVisible,
  setCasinoFiltersSearchValue,
  setCasinoFiltersSearchVisible,
  setInitialCasinoFilters,
  useToggle,
} from '@mwl/core-lib';

import ArrowIcon from '@public/assets/common/icons/arrow.svg';
import FilterIcon from '@public/assets/common/icons/filter.svg';

import { useTypedDispatch, useTypedSelector } from '@/hooks';
import { casinoPageObject } from '@/utils';

import { Button } from '../Button/Button';
import { Dropdown } from '../Dropdown/Dropdown';
import { FormFieldSearch } from '../FormFieldSearch/FormFieldSearch';

import { CasinoFilterList } from './components/CasinoFilterList/CasinoFilterList';
import { providerAnalytics, SEARCH_CLEAR, SEARCH_CLICK, SEARCH_REQUEST } from './CasinoFilters.analytics';
import type { CasinoFiltersProps } from './CasinoFilters.types';
import { useCasinoFilters, useDropdown } from './hooks';

import styles from './CasinoFilters.module.scss';

const CasinoFilters: FC<CasinoFiltersProps> = ({ renderFilters, productType, ...props }) => {
  const { query, pathname } = useRouter();
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const rootRef = useRef<HTMLDivElement | null>(null);
  const searchRef = useRef<HTMLInputElement | null>(null);

  const [showFilters, toggleShowFilters] = useToggle(false);

  const featureProps = useDropdown(false);
  const genreProps = useDropdown(false);
  const providerProps = useDropdown(false);

  useCasinoFilters({ productType });

  const { feature, genre, provider } = useTypedSelector(selectCasinoFilters);
  const isLoading = useTypedSelector(selectCasinoFiltersLoading);
  const showSearch = useTypedSelector(selectCasinoFiltersSearchVisible);
  const searchValue = useTypedSelector(selectCasinoFiltersSearchValue);

  const handleChangeSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;

      dispatch(setCasinoFiltersSearchValue(value));

      if (value.length) {
        sendAnalyticsData(SEARCH_REQUEST, { cls: searchRef.current?.className, search_request: value });
      }
    },
    [dispatch],
  );

  const handleClickSearch = useCallback(() => {
    dispatch(setCasinoFiltersSearchVisible(true));
    dispatch(setInitialCasinoFilters());
    sendAnalyticsData(SEARCH_CLICK, { cls: searchRef.current?.className });
  }, [dispatch]);

  const handleCloseSearch = useCallback(() => {
    dispatch(setCasinoFiltersSearchVisible(false));
    dispatch(setCasinoFiltersResultVisible(false));
  }, [dispatch]);

  const handleClearSearch = useCallback(() => {
    handleCloseSearch();
    sendAnalyticsData(SEARCH_CLEAR, { cls: searchRef.current?.className, search_request: searchValue });
  }, [handleCloseSearch, searchValue]);

  const handleBlur = useCallback(() => {
    if (searchValue.length === 0) {
      handleCloseSearch();
    }
  }, [searchValue.length, handleCloseSearch]);

  const providerLabelText = t('provider', 'Provider');

  useEffect(() => {
    handleCloseSearch();
    dispatch(resetCasinoFilters());

    return () => {
      dispatch(resetCasinoFilters());
      dispatch(reloadCasinoFilters());
    };
  }, [dispatch, handleCloseSearch, pathname, query.category]);

  return (
    <div {...getTestProps(props)} className={cn(styles.root, { [styles.active]: showFilters })} ref={rootRef}>
      <Button
        {...casinoPageObject.casinoFilters.filtersToggle.nodeProps}
        as="button"
        variant="text"
        fontWeight="bold"
        fontSize="lg"
        onClick={() => toggleShowFilters()}
        className={cn(styles.filtersToggle, { [styles.active]: showFilters })}
      >
        <FilterIcon className={styles.filterIcon} />
        {t('sidebar.filter', 'Filter')}
        <ArrowIcon className={styles.arrowIcon} />
      </Button>

      {rootRef.current && (
        <div className={cn(styles.dropdownsWrapper, { [styles.active]: showFilters })}>
          {renderFilters.includes('genre') && (
            <Dropdown
              {...casinoPageObject.casinoFilters.filterSections('genre').nodeProps}
              {...genreProps}
              disabled={isLoading}
              title={t('genre', 'Genre')}
              positionReference={rootRef.current}
              contentTestProps={casinoPageObject.casinoFilters.filterSections('genre').filterContent.nodeProps}
            >
              <CasinoFilterList
                {...casinoPageObject.casinoFilters.filterSections('genre').itemCheckbox.nodeProps}
                items={genre}
                type="genre"
              />
            </Dropdown>
          )}
          {renderFilters.includes('feature') && (
            <Dropdown
              {...casinoPageObject.casinoFilters.filterSections('feature').nodeProps}
              {...featureProps}
              disabled={isLoading}
              title={t('feature', 'Feature')}
              positionReference={rootRef.current}
              contentTestProps={casinoPageObject.casinoFilters.filterSections('feature').filterContent.nodeProps}
            >
              <CasinoFilterList
                {...casinoPageObject.casinoFilters.filterSections('feature').itemCheckbox.nodeProps}
                items={feature}
                type="feature"
              />
            </Dropdown>
          )}
          {renderFilters.includes('provider') && (
            <Dropdown
              {...casinoPageObject.casinoFilters.filterSections('provider').nodeProps}
              {...providerProps}
              disabled={isLoading}
              title={providerLabelText}
              positionReference={rootRef.current}
              contentTestProps={casinoPageObject.casinoFilters.filterSections('provider').filterContent.nodeProps}
              analytics={extendAnalyticsData(providerAnalytics, {
                text: providerLabelText,
                count: renderFilters.length,
                position: renderFilters.indexOf('provider') + 1,
                filter_name: 'provider',
              })}
            >
              <CasinoFilterList
                {...casinoPageObject.casinoFilters.filterSections('provider').itemCheckbox.nodeProps}
                items={provider}
                type="provider"
              />
            </Dropdown>
          )}
        </div>
      )}
      <FormFieldSearch
        {...casinoPageObject.casinoFilters.searchField.nodeProps}
        ref={searchRef}
        value={searchValue}
        onBlur={handleBlur}
        onFocus={handleClickSearch}
        onClear={handleClearSearch}
        onInput={handleChangeSearch}
        placeholder=""
        className={cn(styles.searchField, { [styles.active]: showSearch })}
        classes={{ component: styles.input }}
      />
    </div>
  );
};

export { CasinoFilters };
