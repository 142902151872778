import type { CarouselAnalytics } from '../Carousel/Carousel.types';

export const analytics: CarouselAnalytics = {
  banner: {
    change: {
      eventName: 'mainSlider_autoChange',
    },
    swipe: {
      eventName: 'mainSlider_change_click',
    },
  },
  cta: {
    click: {
      eventName: 'mainSlider_cta_click',
    },
  },
  image: {
    click: {
      eventName: 'mainSlider_img_click',
    },
  },
};
