import type { FC } from 'react';

import { MenuItem } from '../MenuItem/MenuItem';

import type { SidebarItemsGroupProps } from './SidebarTopSection.types';

import styles from './SidebarTopSection.module.scss';

export const SidebarTopSection: FC<SidebarItemsGroupProps> = ({ list }) => {
  return (
    <nav className={styles.root}>
      {list.map((menuItem) => (
        <MenuItem {...menuItem} id={menuItem.key} key={menuItem.key} />
      ))}
    </nav>
  );
};
