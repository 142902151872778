import { useEffect } from 'react';
import { loadCasinoFiltersRequest, selectCasinoFiltersLoaded, selectUserCurrency } from '@mwl/core-lib';

import { useTypedDispatch, useTypedSelector } from '@/hooks';

import type { CasinoFiltersProps } from '../CasinoFilters.types';

interface UseCasinoFiltersParams extends Pick<CasinoFiltersProps, 'productType'> {}

export function useCasinoFilters({ productType }: UseCasinoFiltersParams) {
  const dispatch = useTypedDispatch();

  const currency = useTypedSelector(selectUserCurrency);
  const isLoaded = useTypedSelector(selectCasinoFiltersLoaded);

  useEffect(() => {
    if (currency && !isLoaded) {
      dispatch(loadCasinoFiltersRequest({ productType }));
    }
  }, [currency, dispatch, productType, isLoaded]);
}
