import { Autoplay } from 'swiper/modules';
import type { SwiperProps } from 'swiper/react';

export const sliderParams: SwiperProps = {
  modules: [Autoplay],
  allowTouchMove: false,
  direction: 'vertical',
  observeParents: true,
  observer: true,
  resizeObserver: true,
  updateOnWindowResize: true,
  spaceBetween: 15,
  slidesPerView: 6,
  autoplay: {
    delay: 10_000,
    reverseDirection: true,
    disableOnInteraction: false,
  },
  navigation: false,
  speed: 400,
  loop: true,
};
