import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'next-i18next';
import type { TestProps } from '@mwl/core-lib';
import {
  extendAnalyticsData,
  getTestProps,
  isErrorResult,
  loadCasinoGames,
  resetCasinoFilters,
  selectCasinoFiltersRequest,
  sendAnalyticsData,
} from '@mwl/core-lib';

import { casinoCardsBreakpoints } from '@/constants';
import { useTypedDispatch, useTypedSelector } from '@/hooks';

import { ProviderCardBlock } from '../LoadableProvidersBlock/LoadableProvidersBlock';
import { Loader } from '../Loader/Loader';
import { SearchNotFound } from '../SearchNotFound/SearchNotFound';
import type { SectionGamesLoadableLoad } from '../SectionGamesLoadable/SectionGamesLoadable';
import { SectionGamesLoadable } from '../SectionGamesLoadable/SectionGamesLoadable';

import { SearchResultTitle } from './components/SearchResultTitle/SearchResultTitle';
import { notFoundShowMoreAnalytics, SEARCH_NOT_FOUND, SEARCH_RESULT } from './CasinoSearchResults.analytics';

import styles from './CasinoSearchResults.module.scss';

const CasinoSearchResults: FC<TestProps> = (props) => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation('casino');

  const request = useTypedSelector(selectCasinoFiltersRequest);

  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [providersCount, setProvidersCount] = useState(0);

  const resetFilters = useCallback(() => {
    dispatch(resetCasinoFilters());
  }, [dispatch]);

  const handleLoaded = useCallback(() => {
    setIsLoading(false);
  }, []);

  const loadGames = useCallback<SectionGamesLoadableLoad>(
    async ({ page, platform, currency }) => {
      if (page === 0) {
        setIsLoading(true);
      }

      const { title: _, ...currentRequest } = request;

      const result = await loadCasinoGames(currentRequest)({
        currency,
        platform,
        page,
        size: currentRequest.query?.length ? 50 : 16,
      });

      if (!isErrorResult(result)) {
        setCount(result.totalElements ?? 0);

        if (!result.items.length) {
          sendAnalyticsData(SEARCH_NOT_FOUND, { search_request: currentRequest.query });
        } else if (currentRequest.query?.length) {
          sendAnalyticsData(SEARCH_RESULT, { count: result.totalElements, search_request: currentRequest.query });
        }
      }

      return result;
    },
    [request],
  );

  const showMoreBtnText = t('common:show_more', 'Show more');

  return (
    <div {...getTestProps(props)} className={styles.root}>
      {isLoading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}

      <SectionGamesLoadable
        isInfinite
        breakpoints={casinoCardsBreakpoints}
        loadGames={loadGames}
        totalElements={request.query?.length ? 100 : undefined}
        className={cn(styles.section, isLoading && styles.hidden)}
        onLoadFinished={handleLoaded}
        emptyContent={
          isLoading || providersCount ? null : (
            <SearchNotFound
              className={styles.notFound}
              onClick={resetFilters}
              title={t('common:no_results', 'No results found:(')}
              text={t('common:nothing_found', 'Nothing found for this query')}
              buttonText={showMoreBtnText}
              buttonAnalytics={extendAnalyticsData(notFoundShowMoreAnalytics, {
                search_request: request.query,
                text: showMoreBtnText,
              })}
            />
          )
        }
        title={<SearchResultTitle text={t('found_games', 'Found games')} count={count >= 100 ? 100 : count} />}
        resetParams={request}
      />
      {!!request.query?.length && (
        <ProviderCardBlock
          isInfinite
          title={<SearchResultTitle text={t('found_providers', 'Found providers')} count={providersCount} />}
          resetParams={request}
          requestParams={request}
          setItemsCount={setProvidersCount}
          breakpoints={casinoCardsBreakpoints}
          className={cn(styles.section, { [styles.hidden]: providersCount === 0 })}
        />
      )}
    </div>
  );
};

export { CasinoSearchResults };
