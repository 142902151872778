import type { FC, MouseEvent, MouseEventHandler } from 'react';
import { memo, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useRouter } from 'next/router';
import { extendAnalyticsData, getTestProps, sendAnalyticsData } from '@mwl/core-lib';

import ArrowLeftIcon from '@public/assets/common/icons/arrowLeftRound.svg';
import ArrowRightIcon from '@public/assets/common/icons/arrowRightRound.svg';

import { Button } from '@/components/Button/Button';
import { Image } from '@/components/Image/Image';

import type { CarouselItemAnalyticsData, CarouselItemProps } from './CarouselItem.types';

import styles from './CarouselItem.module.scss';

const BaseCarouselItem: FC<CarouselItemProps> = ({
  item,
  currentIndex,
  activeIndex,
  onClick,
  onMouseEnter,
  onMouseLeave,
  slideSize,
  offsetSize,
  carouselLength,
  analytics,
  ...props
}) => {
  const router = useRouter();
  const active = activeIndex === currentIndex;
  const rootRef = useRef<HTMLDivElement | null>(null);
  const buttonsRef = useRef<HTMLDivElement | null>(null);
  const [isShowImg, setIsShowImg] = useState(true);

  const analyticsData: CarouselItemAnalyticsData = item
    ? {
        href: item.url,
        img: item.image,
        position_weight: item.weight,
        id: item.id,
        count: carouselLength,
      }
    : {};

  const getTranslateValue = () => {
    let offsetValue = offsetSize * currentIndex;

    if (currentIndex < activeIndex) {
      offsetValue -= slideSize;
    }

    return `translateX(${offsetValue}px`;
  };

  const handleClick: MouseEventHandler<HTMLDivElement> = (event?: MouseEvent<HTMLDivElement>) => {
    if (buttonsRef.current?.contains(event?.target as HTMLElement)) {
      return;
    }

    const idx = currentIndex + 1;
    const realTarget = idx > carouselLength ? (idx % carouselLength) - 1 : currentIndex;

    if (activeIndex !== realTarget) {
      onClick?.(realTarget);
    } else {
      if (!(event?.target as HTMLElement).closest('[data-button-cta]')) {
        sendAnalyticsData(analytics?.image?.click?.eventName, {
          ...(analytics?.image?.click?.data || {}),
          ...analyticsData,
          cls: event?.currentTarget.className,
        });
      }

      if (item?.url) {
        router.push(item.url);
      }
    }
  };

  const handlePrev = () => {
    const nextIndex = currentIndex - 1;

    if (nextIndex >= 0) {
      onClick?.(nextIndex);
    }
  };

  const handleNext = () => {
    const idx = currentIndex + 2;
    const realTarget = idx > carouselLength ? (idx % carouselLength) - 1 : currentIndex + 1;
    onClick?.(realTarget);
  };

  const { ref, ...handlers } = useSwipeable({
    preventScrollOnSwipe: true,
    onSwipeStart: () => {
      onMouseEnter?.();
    },

    onSwiped: () => {
      onMouseLeave?.();
    },

    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
  });

  return (
    <div
      {...getTestProps(props)}
      ref={(value) => {
        ref(value);
        rootRef.current = value;
      }}
      style={{
        transform: getTranslateValue(),
        zIndex: carouselLength - currentIndex,
      }}
      className={cn(styles.root, {
        [styles.left]: currentIndex < activeIndex,
        [styles.right]: currentIndex > activeIndex,
        [styles.active]: active,
        [styles.hasUrl]: !!item?.url,
      })}
      role="presentation"
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...handlers}
    >
      <div className={styles.slider}>
        {!!item?.image && (
          <Image
            src={item?.image}
            width={750}
            alt={item?.title || 'Slide'}
            priority
            fill
            style={{ objectFit: 'cover', display: isShowImg ? 'block' : 'none' }}
            onError={() => setIsShowImg(false)}
          />
        )}
      </div>
      {(item?.button || item?.title || item?.content) && (
        <div className={styles.contentContainer}>
          <div>
            {item?.title && <h1 className={styles.title}>{item?.title}</h1>}
            {item?.content && <h2 className={styles.subtitle}>{item?.content}</h2>}
          </div>
          {item?.button && (
            <Button
              as="button"
              className={styles.button}
              variant="fulfilled"
              align="center"
              size="lg"
              fontWeight="bold"
              fontSize="xl"
              color="red"
              rounded
              data-button-cta="true"
              analytics={extendAnalyticsData(analytics?.cta, { ...analyticsData, text: item?.button })}
            >
              {item?.button}
            </Button>
          )}
        </div>
      )}
      <div
        ref={buttonsRef}
        className={cn(styles.pagination, {
          [styles.hidden]: !active,
        })}
      >
        <ArrowLeftIcon
          className={cn(styles.prev, {
            [styles.disabled]: currentIndex - 1 < 0,
          })}
          onClick={handlePrev}
        />

        <ArrowRightIcon className={styles.next} onClick={handleNext} />
      </div>
    </div>
  );
};

const CarouselItem = memo(BaseCarouselItem);

export { CarouselItem };
