import type { Analytics } from '@mwl/core-lib';

export const SEARCH_CLICK = 'casinoMenu_search_click';
export const SEARCH_REQUEST = 'casinoMenu_search_request';
export const SEARCH_CLEAR = 'casinoMenu_searchClear_click';

export const providerAnalytics: Analytics<'open' | 'close'> = {
  open: {
    eventName: 'casinoFilter_provider_open',
  },
  close: {
    eventName: 'casinoFilter_provider_close',
  },
};
