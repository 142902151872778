import type { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { Button } from '@/components/Button/Button';
import { CardGrid } from '@/components/CardGrid/CardGrid';
import { LoadableItemsWrapper } from '@/components/LoadableItemsWrapper/LoadableItemsWrapper';

import { GamesGridLayout } from '../GamesGridLayout/GamesGridLayout';
import { ProviderCard } from '../ProviderCard/ProviderCard';

import type { SectionProvidersProps } from './SectionProviders.types';

import styles from './SectionProviders.module.scss';

const SectionProviders: FC<SectionProvidersProps> = ({
  title,
  hasNext,
  providers,
  className,
  isInfinite,
  scrollThreshold = 0.55,
  loadNext,
  ...props
}) => {
  const { t } = useTranslation('casino');

  const loadableProps = isInfinite
    ? ({
        as: 'infinite',
        dataLength: providers.length,
        next: loadNext ?? (() => {}),
        hasMore: !!hasNext,
        scrollThreshold,
      } as const)
    : ({
        as: 'block',
      } as const);

  return (
    <GamesGridLayout title={title} className={className}>
      <LoadableItemsWrapper {...loadableProps}>
        <CardGrid hasNext={hasNext} isInfinite={isInfinite} {...props}>
          {providers.map((provider) => (
            <ProviderCard {...provider} width="auto" key={provider.id} />
          ))}
        </CardGrid>
      </LoadableItemsWrapper>
      {loadNext && hasNext && !isInfinite && (
        <div className={styles.buttonContainer}>
          <Button
            as="button"
            fontWeight="bold"
            rounded
            fontSize="lg"
            size="lg"
            variant="fulfilled"
            color="red"
            onClick={loadNext}
          >
            {t('more_providers', 'More provider')}
          </Button>
        </div>
      )}
    </GamesGridLayout>
  );
};

export * from './SectionProviders.types';
export { SectionProviders };
