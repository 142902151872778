import type { FC } from 'react';
import { memo } from 'react';
import type { LoadableItemsWrapperBaseProps } from '@mwl/ui';
import { LoadableItemsWrapperBase } from '@mwl/ui';

const BaseLoadableItemsWrapper: FC<LoadableItemsWrapperBaseProps> = (props) => {
  return <LoadableItemsWrapperBase {...props} />;
};

export const LoadableItemsWrapper = memo(BaseLoadableItemsWrapper);
